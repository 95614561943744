import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

interface OptionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  setUseVirtualKeyboard: React.Dispatch<React.SetStateAction<boolean>>;
  useVirtualKeyboard: boolean;
  // New props for the "Best Score Display" option:
  competitive: boolean;
  setCompetitive: React.Dispatch<React.SetStateAction<boolean>>;
}

const OptionsModal: React.FC<OptionsModalProps> = ({
  isOpen,
  onClose,
  setUseVirtualKeyboard,
  useVirtualKeyboard,
  competitive,
  setCompetitive,
}) => {
  const handleVirtualKeyboardToggle = () => {
    const newSetting = !useVirtualKeyboard;
    setUseVirtualKeyboard(newSetting);
    localStorage.setItem("useVirtualKeyboard", String(newSetting));
  };

  const handleCompetitiveToggle = () => {
    const newSetting = !competitive;
    setCompetitive(newSetting);
    localStorage.setItem("competitive", String(newSetting));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Options</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="use-virtual-keyboard" mb="0">
              Use Virtual Keyboard
            </FormLabel>
            <Switch
              id="use-virtual-keyboard"
              isChecked={useVirtualKeyboard}
              onChange={handleVirtualKeyboardToggle}
              colorScheme="orangeBrand"
            />
          </FormControl>

          {/* New option for Best Score Display */}
          <FormControl display="flex" alignItems="center" mt={4}>
            <FormLabel htmlFor="competitive" mb="0">
              Best Score Display
            </FormLabel>
            <Switch
              id="competitive"
              isChecked={competitive}
              onChange={handleCompetitiveToggle}
              colorScheme="orangeBrand"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="orangeBrand" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OptionsModal;
