import React, { useState, useCallback, SetStateAction, Dispatch } from "react";
import RoundDisplay from "./RoundDisplay";
import {
  isValidTransformation,
  isValidWord,
  isRareWord,
} from "../../utils/utils";
import { useRareWordList } from "../../RareWordListContext";
import type { Move, RoundType } from "../types";

export type RoundProps = {
  currentRoundIndex: number
  rounds: RoundType[]
  startWord: string;
  goalWord: string;
  moves: Move[];
  addMove: (move: string, direction: "forward" | "backward" | "both") => void;
  wordList: string[];
  undoMove: () => void;
  undoBackwardsMove: () => void;
  clearMoves: () => void;
  rareWordsUsed: string[];
  isRoundOver: boolean;
  firstPlay: boolean;
  craftwordsBestPathLength: number | null;
  setShowCraftwordBestAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showCraftwordBestAlert: boolean;
  isMobile: boolean;
  useVirtualKeyBoard: boolean;
  onHintRequested?: () => void;
  onGiveUpRequested?: () => void;
  allHintsUsed?: boolean;
  showRightArrow?: boolean;
  showLeftArrow?: boolean;
  showStartOver?: boolean;
  showHelpIcon?: boolean;
  currentPosition?: number;
  competitive: boolean;
};

const Round: React.FC<RoundProps> = ({
  currentRoundIndex,
  rounds,
  startWord,
  goalWord,
  moves,
  addMove,
  wordList,
  undoMove,
  undoBackwardsMove,
  clearMoves,
  rareWordsUsed,
  isRoundOver,
  firstPlay,
  craftwordsBestPathLength,
  setShowCraftwordBestAlert,
  showCraftwordBestAlert,
  isMobile,
  useVirtualKeyBoard,
  onHintRequested,
  onGiveUpRequested,
  allHintsUsed,
  showRightArrow,
  showLeftArrow,
  showStartOver,
  showHelpIcon,
  currentPosition,
  competitive,
}) => {
  const [invalidWordError, setInvalidWordError] = useState<string | null>(null);
  const [invalidMoveError, setInvalidMoveError] = useState<string | null>(null);
  const currentWord =
    moves.length > 0 ? moves[moves.length - 1].word : startWord;
  const { rareWordList } = useRareWordList();
  const [rareWordAlert, setRareWordAlert] = useState<string | null>(null);
  const [craftwordBestAlert, setCraftwordBestAlert] = useState<string | null>(
    null,
  );
  const firstPlayWordList = ["food", "mood", "doom", "door", "donor"];
  const [errorCount, setErrorCount] = useState(0);
  const [showInvalidWordError, setShowInvalidWordError] = useState(false);
  const [showInvalidMoveError, setShowInvalidMoveError] = useState(false);
  const [showRareWordAlert, setShowRareWordAlert] = useState(false);

  const checkTransformation = useCallback(
    (userInput: string, clearInput: () => void) => {
      const normalizedInput = userInput.toLowerCase();
      const isWordAlreadyUsed = moves.some(
        (move) => move.word === normalizedInput,
      );
      const safeCurrentPosition =
        typeof currentPosition === "number" &&
        currentPosition >= 0 &&
        currentPosition <= moves.length
          ? currentPosition
          : 0;

      const beforeWord =
        safeCurrentPosition > 0
          ? moves[safeCurrentPosition - 1].word
          : startWord;
      const afterWord =
        safeCurrentPosition < moves.length
          ? moves[safeCurrentPosition].word
          : goalWord;

      const isValidBeforeMove =
        isValidTransformation(beforeWord, normalizedInput) &&
        isValidWord(normalizedInput, wordList);
      const isValidAfterMove =
        isValidTransformation(afterWord, normalizedInput) &&
        isValidWord(normalizedInput, wordList) &&
        normalizedInput !== goalWord;

      if ((isValidBeforeMove || isValidAfterMove) && !isWordAlreadyUsed) {
        clearInput();
        if (isValidBeforeMove && isValidAfterMove) {
          addMove(normalizedInput, "both");
        } else if (isValidBeforeMove) {
          addMove(normalizedInput, "forward");
        } else {
          addMove(normalizedInput, "backward");
        }

        if (isRareWord(normalizedInput, rareWordList)) {
          setRareWordAlert("That's a rare word!");
          setShowRareWordAlert(true);
        }
      } else {
        clearInput();
        setErrorCount((prevCount) => prevCount + 1);

        if (!isValidWord(normalizedInput, wordList)) {
          setInvalidWordError("Not in word list");
          setShowInvalidWordError(true);
        }
        if (!isValidBeforeMove && !isValidAfterMove) {
          setInvalidMoveError("Move not allowed");
          setShowInvalidMoveError(true);
        }
        if (isWordAlreadyUsed) {
          setInvalidWordError("Word already used");
          setShowInvalidWordError(true);
        }
      }
    },
    [
      moves,
      addMove,
      wordList,
      rareWordList,
      currentPosition,
      isValidTransformation,
      isValidWord,
      startWord,
      goalWord,
      setInvalidWordError,
      setInvalidMoveError,
      setShowInvalidWordError,
      setShowInvalidMoveError,
      setErrorCount,
      setRareWordAlert,
      setShowRareWordAlert,
    ],
  );

  const handleShowCraftwordBestAlert = () => {
    setShowCraftwordBestAlert(false);
    setCraftwordBestAlert(
      firstPlay
        ? ""
        : `CraftWord's solution: ${craftwordsBestPathLength} moves`,
    );
    setTimeout(() => {
      setShowCraftwordBestAlert(true);
    }, 20);
  };

  return (
    <RoundDisplay
      currentRoundIndex={currentRoundIndex}
      rounds={rounds}
      wordPair={[startWord, goalWord]}
      onSubmitWord={checkTransformation}
      pastMoves={moves}
      invalidMoveError={invalidMoveError}
      invalidWordError={invalidWordError}
      rareWordAlert={rareWordAlert}
      craftwordBestAlert={craftwordBestAlert}
      showInvalidWordError={showInvalidWordError}
      showInvalidMoveError={showInvalidMoveError}
      showRareWordAlert={showRareWordAlert}
      showCraftwordBestAlert={showCraftwordBestAlert}
      setShowInvalidWordError={setShowInvalidWordError}
      setShowInvalidMoveError={setShowInvalidMoveError}
      setShowRareWordAlert={setShowRareWordAlert}
      setShowCraftwordBestAlert={setShowCraftwordBestAlert}
      errorCount={errorCount}
      undoMove={undoMove}
      undoBackwardsMove={undoBackwardsMove}
      rareWordsUsed={rareWordsUsed}
      isRoundOver={isRoundOver}
      clearMoves={clearMoves}
      isMobile={isMobile}
      craftwordsBestPathLength={craftwordsBestPathLength}
      handleShowCraftwordBestAlert={handleShowCraftwordBestAlert}
      firstPlay={firstPlay}
      useVirtualKeyBoard={useVirtualKeyBoard}
      onHintRequested={onHintRequested}
      onGiveUpRequested={onGiveUpRequested}
      allHintsUsed={allHintsUsed}
      showRightArrow={showRightArrow}
      showLeftArrow={showLeftArrow}
      showStartOver={showStartOver}
      showHelpIcon={showHelpIcon}
      currentPosition={currentPosition}
      competitive={competitive}
    />
  );
};

export default Round;
